.root {
  width: '100%';
}
  
.twoInRow {
	display: flex;
	gap: 24px;
}

.settingsCardContainer {
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 32px;
	display: flex;
	flex-direction: row;
}

.settingsCardDescription {
	display: flex;
	flex-direction: column;
	max-width: 550px;
	width: 100%;
	padding-right: 5%;
}

.settingsCardContentContainer {
	display: flex;
	flex-direction: column;
}

.appSettingsHr {
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);	
	padding-top: 24px;
	margin-bottom: 16px;
}