.root{
  width: '100%';
}

.paper{
  width: '100%';
  margin-bottom: 20px;
}


.container {
  width: 100%;
  height: 100%;
  padding-top: 5px;
  position: relative;
}

.map{
  height: 100%;
  min-height: 650px;
}