.tickerCell {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  > .tickerCellValue {
      font-size: 16px;
      font-weight: 700;
      height: 20px;
  }
  
  > .tickerCellLabel {
      font-size: 12px;
      font-style: italic;
  }
}