.container {
	width: 100%;
	height: 20vw;
	position: relative;
}

.containerPhoto {
	width: 100%;
	height: 40vw;
	position: relative;
}
