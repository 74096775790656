.ProseMirror {
	border-radius: 8px;
	color: #a4a4a4;
	min-height: 184px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 1px;
	padding-bottom: 1px;

	border: 1px solid;
}

.ProseMirror:focus {
	border: 2px solid #139074 !important;
	outline: none !important;
}

.ProseMirror-focused {
	border-color: none !important;
}

.editor-field::v-deep(.ProseMirror) {
	outline: none;
}
