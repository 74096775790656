.videoRow {
  .videoItem {
    padding: 16px;
  }
}

.customHeader {
  padding: 0 16px;
  text-align: left;
}

.selectedVideos {
  padding: 32px 16px;
  color: #f50057;
  background-color: rgb(255, 226, 236);
  h3{

  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 16px;
  span {
  }
}

.inputs {
  width: 100%;
}

.modal {
  padding: 20px;
}
