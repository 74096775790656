
.tickerShell {
  align-self: stretch;
  display: flex;
  border: 2px solid #FFA41D;
  border-radius: 4px;
  padding: 5px 0;
  width: 343px;
  height: 50px;
  margin-right: 24px;
}
.separator {
  display: flex;
  align-self: center;
  font-size: 18px;
  font-weight: bold;
}