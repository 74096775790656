.paperContainer {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px;
}

.missionsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.projectItem {
  margin: 15px 0;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  &:first-child {
    margin-right: 30px;
  }
}

.renderedItems {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.pagination {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.search {
  @media (min-width: 992px) {
    margin-left: auto !important;
  }
}

.filterContainer {
  display: flex;
}