@import '../../../style/_variables.scss';

.container {
	cursor: pointer;
	min-width: fit-content;
	min-height: fit-content;
	position: absolute;
	left: 0;
	bottom: 40px;
	background: #fcfcfc;
	border: 1px solid #f1f1f1;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.item {
		color: #a4a4a4;
		line-height: 24px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		padding: 5px;

		&:hover {
			color: #fcfcfc;
			background-color: black;
		}
	}
}
