.infoText {
	font-size: 1.5em;
}

.mainContainer {
	display: flex;
	flex-direction: column;
}

.generalInfo {
	gap: 16px;
	padding: 8px;
}

.videoInfo {
	display: flex;
	flex-direction: column;
	gap: 8px;
	color: #494f4d;
	.videoInfoRow {
		display: flex;
		gap: 16px;
	}
}
.videoMapSection {
	position: relative;
	height: 350px;
	.videoPlayer {
		width: 48%;
	}
}
.videoActions {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
	.actionButtons {
		position: relative;
		display: flex;
		align-items: center;
		gap: 10px;
		.thumbsApprove {
			color: #139074;
			padding-left: 6px;
		}
		.thumbsReject {
			color: #fe5f55;
			padding-left: 6px;
		}
	}
}
.content {
	border-radius: 20px;
	box-shadow: 0px 6px 19px -15px;
	height: fit-content;

	.collectablesData {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		box-shadow: none !important;
		.collectable {
			margin: 0 10px;
			.fruitImg {
				position: relative;
				width: 16px;
				height: 16px;
				margin-right: 12px;
				top: 2px;
			}
			.collectablesInfo {
				margin: auto;
				height: 16px;
			}
		}
	}

	.line {
		height: 18px;
		width: 1px;
		background: rgba(0, 0, 0, 0.6);
	}
}

.backButton {
	position: relative;
	right: 20px;
	margin-bottom: 12px;
	img {
		margin-right: 4px;
	}
}

.tags {
	margin-left: 16px;
	& > * {
		margin-right: 10px;
	}
}

form {
	border-color: red;
}
