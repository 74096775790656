.parent {
	width: 100%;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
}

.buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.missionHeader {
	display: flex;
	justify-content: space-between;

	.headerLeft {
		display: flex;
		flex-direction: column;
	}

	.headerRight {
		display: flex;
	}

	.buttonGroup {
		display: flex;
		gap: 12px;
	}
}
.timeContainer {
	display: flex;
	border: 2px solid #ffa41d;
	margin-right: 24px;
	border-radius: 4px;
	justify-content: space-between;
	width: 343px;
	height: 50px;
	padding: 5px 10px;

	.timeColumn {
		display: flex;
		align-items: center;
		flex-direction: column;
		.timeElement {
			height: 19px;
			font-size: 12px;
		}
		.numberElement {
			height: 19px;
			font-size: 16px;
			font-weight: 700;
		}
	}

	.divider {
		display: flex;
		align-self: center;
	}
}
