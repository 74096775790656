.imageList {
	display: flex;
	min-height: 400px;
	min-width: 400px;
	overflow-x: auto;
}

.photo {
	max-height: 400px;
	max-width: 400px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.dialogContent {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.link {
	text-decoration: none;
}

.dialog {
	width: 100%;
	max-width: 977px;
	height: 635px;
}

.controls {
	display: flex;
	justify-content: center;
}
