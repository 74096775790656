.highlight {
  color: #59ac52 !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.languageMenu{
    color: #FFF !important;
}

.logo {
  height: 50px;
  margin-right: 30px;
}

.smallSideNav {
  width: 50px;
}

.drawerContainer{
    position: relative;
    color: white!important;
    background-color: #0A775F;
}

.hightlight{
    background-color: rgba(63, 81, 181, 0.2) !important;
}