@import '../../../style/_variables.scss';

.container {
	display: flex;
	flex-direction: column;
	width: 95%;
	min-height: 60vh;
	border-radius: 24px;
	background-color: $white;
	.actions {
		margin-left: auto;
		align-self: center;
	}

	@media (min-width: 992px) {
		width: 50%;
	}
}

.contentContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.collectablesContainer {
	padding: 25px 25px 15px;
}

.collectableValues {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (min-width: 992px) {
		flex-direction: row;
	}

	.totalCollected {
		padding: 0 60px 20px 0;
	}

	.totalValue {
		padding: 0 0 20px 25px;
	}
}

.collectables {
	display: flex;
	align-items: center;
	margin-top: 12px;
}

// .header {
//   margin-top: 3em;
//   display: flex;
//   flex-direction: column;
//   width: 20em;
//   justify-content: space-between;

//   @media (min-width: 992px) {
//     flex-direction: row;
//   }
// }

.title {
	display: flex;
	font-size: 2em;
	cursor: pointer;
	font-weight: 600;
	color: #494f4d;
	align-items: center;
}

.projectDescription {
	padding-bottom: 15px;
}

.button {
	position: absolute;
	left: 25px;
	bottom: 25px;
	margin-top: 25px !important;
	padding: 6px 16px !important;
	font-size: 15px !important;
}

.buttonDelete {
	position: absolute;
	width: 285px;
	height: 55px;
	border: 1.5px solid red !important;
	background-color: white !important;
	color: red !important;
	align-self: flex-end;
	margin: 0 25px 25px 0 !important;
	box-shadow: none !important;
	font-size: 15px !important;
}

.fruitImg {
	width: 16px;
	height: 16px;
}
