.root  {
height: 100vh;
}

.error{
    color: red;
    margin-top: 10px;
}

.success{
    color: green;
    margin-top: 10px;
}

.inputs{
    width: 260px;
}