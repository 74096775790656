.searchbar {
	background-color: #ffffff;
	z-index: 1;
	position: absolute;
	top: 64px;
	left: 58px;
	margin: 12px;
	border-radius: 8px;
	padding: 8px;
	display: flex;
	flex-direction: row;
	gap: 20px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.map {
	height: 100vh;
	width: 100%;
}

.overlay {
	position: fixed;
	top: 64px;
	left: 55px;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; // Ensure it's on top of other elements
}

.indevbanner {
	background-color: orange;
	color: white;
	text-align: center;
	padding: 5px;
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	width: 200px;
	border-radius: 10px;
	z-index: 999;
}
