@import "../../../style/_variables.scss";

.paper {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px;
}

.content {
  gap: 16px;
  padding: 8px;
}
