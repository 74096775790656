.pageContainer {
    padding: 32px;
    display: flex;
}

.editorContainer {
    padding-right: 32px;
}

.phone {
  position: relative;
  width: 300px;
  height: 600px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

.phone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.phone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

.phone .content {
  width: 360px;
  height: 640px;
  background: white;
}

.topBar {
  height: 20px; 
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

a { 
  color: #139074;
  text-decoration: none;
  
}