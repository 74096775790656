.content {
	gap: 16px;
	padding: 24px;

	.status {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.videoPlayer {
		height: 200px;
	}
	.videoInfo {
		display: flex;
		flex-direction: column;
		width: 200px;
		white-space: nowrap;
		gap: 4px;

		.fileName {
			font-size: 14px;
			color: #a4a4a4;
		}
	}
	.videoActions {
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		&:hover {
			cursor: pointer;
		}
		.thumbsApprove {
			color: #139074;
			padding-left: 6px;
		}
		.thumbsReject {
			color: #fe5f55;
			padding-left: 6px;
		}
	}
}

.selectedVideo {
	border: 2px solid #3f51b5;
}
