.root {
	padding-top: 0;
	.notificationForm {
		display: flex;
		flex-direction: column;
		gap: 32px;
		.sendButton {
			width: 100%;
			.submitButton {
				width: 100%;
			}
		}
	}
	.twoInRow {
		display: flex;
		gap: 26px;
	}

	.dropzoneLabelContainer {
		margin-top: 20px;
		display: flex;
		img {
			margin-right: 8px;
		}
	}

	.uploadedImagesFileContainer {
		display: flex;
		justify-content: space-between;
		margin-top: 12px;
		.uploadedImagesFile {
			display: flex;
			flex-direction: column;
		}
		img {
			margin-right: 8px;
		}
	}

	.radioRoot {
		margin-bottom: 24px;
		width: 650px;
		.radioContainer {
			display: flex;
			flex-flow: row wrap;
			gap: 12px;
		}
	}
}

.messagebox {
	max-width: min-content;
	min-width: 600px;
}
.backButton {
	position: relative;
	right: 20px;
	img {
		margin-right: 4px;
	}
}
